import { createStore } from 'vuex'
import { Modal } from 'ant-design-vue';
import { getUserInfo } from "@/utils/Util";
import { doGetRequest, doPostRequest } from '@/utils/request';
import Study_acute_info from '@/store/modules/Study_acute_info';
import Study_other_info from '@/store/modules/Study_other_info';
import Study_clinical_info from './modules/Study_clinical_info';
import Study_genotoxicity_info from './modules/Study_genotoxicity_info';
export default createStore({
  modules: {
    Study_acute_info,
    Study_other_info,
    Study_clinical_info,
    Study_genotoxicity_info
  },
  state: {
    selectreference: null,
    isLoading: {
      api: "",
      value: false,
    },
    items: [],
    selectedTags: [],
    clinicalData: [],
    non_clinicalData: [],
    userInfo: getUserInfo(),
    tags: [],
    Page: 1,
    APIdetail: {
      APIDetails: false,
      items: false,
      non_clinicalStudyData: false,
      clinicalStudyData: false,
      Tags: false,
      AcuteToxData: false,
      OtherToxData: false,
      clinicalinfoData: false,
      genotoxicityData: false,
    },
    PageSize: 10,
    searchFormula: "",
    searchName: "",
    molecular_formula: "",
    currentFilters: {
      APID: null,
      study_type: null
    },
    //from homeview to detailsview
    APID: null,
    //different page fixed
    study_type_clinical: 'clinical',
    study_type_non_clinical: 'non_clinical',
    hashsearchresult: null,
    addreferenceresult: null,
    APIlist: [],
    details: {},
    tnum: 0,
  },
  getters: {
    isAPIDetailComplete: (state) => {
      // 检查 APIdetail 中的所有值是否都为 true
      return Object.values(state.APIdetail).every(value => value === true);
    },
    //默认值｜｜登陆值,测试版本 0 和10
    profile_picture: (state) => state.userInfo.headimgurl || 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    username: (state) => state.userInfo.unionid || 'guest',
    usernickname: (state) => state.userInfo.nickname || 'guest',
    admin_gid: (state) => (state.userInfo.admin_gid) || 0,
    getColor: (state) => (tag, isSelected) => {
      return isSelected ? (state.tags.find(t => t.tag === tag).tag_color || 'default') : 'grey';
    },
    getitemColor: (state) => (tag) => {
      // 查找匹配的标签对象
      const tagObject = state.tags.find(t => t.tag === tag);
      // 如果找到了，返回对应的颜色，否则返回 undefined 或默认颜色
      return tagObject ? tagObject.tag_color : undefined; // 或使用默认颜色，例如 'grey'
    },
    // Define a getter to return a Set of all tags
    tagSet(state) {
      const set = new Set();
      state.tags.forEach(tagObject => {
        set.add(tagObject.tag);
      });
      // 将 Set 转换回数组并返回
      return Array.from(set);
    },
    itemtagSet(state) {
      const set = new Set();
      state.items.forEach(item => {
        item.tags.forEach(tag => {
          set.add(tag);
        });
      });
      // 将 Set 转换回数组并返回
      return Array.from(set);
    },

    filteredItems: state => {
      // If no tags are selected, return all items
      if (!state.selectedTags.length) {
        return state.items;
      }

      return state.items.filter(item => state.selectedTags.every(tag => item.tags.includes(tag))
      );
    },

  },
  // mutations is synchronous used to update state
  mutations: {

    setisLoading(state, api) {
      state.isLoading.value = true;
      state.isLoading.api = api;

    },
    cancelisLoading(state, api) {
      state.isLoading.value = false;
      state.isLoading.api = api;
    },
    setSelectreference(state, selectreference) {
      state.selectreference = selectreference;
    },
    setPage(state, Page) {
      state.Page = Page;
    },
    setPageSize(state, PageSize) {
      state.PageSize = PageSize;
    },

    setSearchName(state, newName) {
      state.searchName = newName;
    },

    setSearchFormula(state, newFormula) {
      state.searchFormula = newFormula;

    },
    UPDATE_DETAIL(state, { APID, field, value }) {
      const detail = state.details.find(d => d.APID === APID);
      if (detail) {
        detail[field] = value;
      }
    },
    settnum(state, tnum) {
      //将string转换为number
      state.tnum = Number(tnum);
    },
    setdetails(state, details) {
      state.details = details;
    },
    setAPIlist(state, APIlist) {
      state.APIlist = APIlist;
    },
    setAPID(state, APID) {
      state.APID = APID;
    },
    sethashsearchresult(state, hashsearchresult) {
      state.hashsearchresult = hashsearchresult;
    },
    setaddreferenceresult(state, addreferenceresult) {
      state.addreferenceresult = addreferenceresult;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    SET_USERINFO(state) {
      state.userInfo = getUserInfo();
    },
    SET_CLINICAL_DATA(state, data) {
      state.clinicalData = data;
    },
    SET_NON_CLINICAL_DATA(state, data) {
      state.non_clinicalData = data;
    },
    SET_FILTERS(state, filters) {
      state.currentFilters = filters;
    },
    setItems(state, items) {
      state.items = items;
    },
    // Define a getter to return a Set of selected tags
    //切换选中状态
    toggleTag(state, tag) {
      const index = state.selectedTags.indexOf(tag);
      if (index === -1) {
        state.selectedTags.push(tag);
      } else {
        state.selectedTags.splice(index, 1);
      }
    },
  },
  actions: {
    // updateDetail({ commit }, { APID, field, value }) {
    //   commit('UPDATE_DETAIL', { APID, field, value });
    // },
    cancelLoading({ commit }, api) {
      commit('cancelisLoading', api);
    },
    checkAndSetLoadingFalse({ dispatch, getters }) {
      console.log("APIdetaillist", this.state.APIdetail)
      if (getters.isAPIDetailComplete) {
        dispatch('cancelLoading', "APIDetailpage");
      }
    },
    setLoading({ commit }, api) {
      commit('setisLoading', api);
    },

    //selectedreference
    setSelectreference({ commit }, selectreference) {
      commit('setSelectreference', selectreference);
    }
    ,

    async updateDetail({ dispatch }, payload) {
      try {
        if (payload.noCache === undefined) {
          payload.noCache = true;
        }
        const response = await doPostRequest('/hkpma/api/detail', payload);
        console.log("updateDetailresponse", response)
        dispatch('getAPIDetails', {
          APID: payload.APID,
          noCache: true,
        });

      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    //search details/get details
    async getAPIlist({ commit, dispatch }, params) {
      try {
        console.log("getAPIlistparams", params)
        const response = await doGetRequest('/hkpma/api/list',
          params
        );
        console.log("getAPIlistresponse", response)
        commit('setAPIlist', response.data.content);
        commit('settnum', response.data.tnum);
        //取消loading
        console.log("isLoading", this.state.isLoading)
        if (this.state.isLoading.api === "getAPIlist") {
          dispatch('cancelLoading', "getAPIlist");
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    async getAPIDetails({ commit, dispatch }, params) {
      try {
        console.log("getAPIdetailparams", params)
        const response = await doGetRequest('/hkpma/api/detail', params);
        console.log("getAPIdetail response", response)
        commit('setdetails', response.data.content);
        if (this.state.isLoading.api === "APIDetailpage") {
          this.state.APIdetail.APIDetails = true;
          dispatch("checkAndSetLoadingFalse");
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },

    //search reference
    async hashsearch({ commit }, payload) {
      try {
        const response = await doPostRequest('/hkpma/references', payload);
        console.log("hashsearchresponse", response)
        commit('sethashsearchresult', response.data.content);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },

    //add reference
    async addreference({ dispatch, commit }, payload) {
      try {
        if (payload.noCache === undefined) {
          payload.noCache = true;
        }
        const response = await doPostRequest('/hkpma/references', payload);
        dispatch('fetchItems', { option: "get", APID: this.state.APID, noCache: true });
        console.log("addreferenceresponse", response)
        commit('setaddreferenceresult', response.data.content);
      }
      catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    //add api reference
    setUserinfo({ commit }) {
      commit('SET_USERINFO');
    },
    async addItem({ dispatch }, item) {
      try {
        if (item.noCache === undefined) {
          item.noCache = true;
        }
        const response = await doPostRequest('/hkpma/api/references', item);
        dispatch('fetchItems', { option: "get", APID: this.state.APID, noCache: true });

        console.log("addItemresponse", response);
        return response.data.content;
      } catch (error) {
        console.error('Error adding item:', error);
      }
    },
    //delete api reference
    async deleteItem({ dispatch }, item) {
      try {
        if (item.noCache === undefined) {
          item.noCache = true;
        }
        const response = await doPostRequest('/hkpma/api/references', item);
        dispatch('fetchItems', { option: "get", APID: this.state.APID, noCache: true });
        console.log("deleteItemresponse", response);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
    toggleTag({ commit }, tag) {
      commit('toggleTag', tag);
    },

    async addTag({ dispatch }, payload) {
      try {
        if (payload.noCache === undefined) {
          payload.noCache = true;
        }
        const response = await doPostRequest('/hkpma/tags', payload);
        const data = { "option": "get", noCache: true };
        dispatch('fetchtags', data);
        console.log("addTagresponse", response);
      } catch (error) {
        console.error('Error adding tag:', error);
        // Handle the error appropriately
      }
    },
    async additemTag({ dispatch }, payload) {
      try {
        if (payload.noCache === undefined) {
          payload.noCache = true;
        }
        const response = await doPostRequest('/hkpma/api/references/tags', payload);
        dispatch('fetchItems', {
          noCache: true,
          option: "get",
          APID: this.state.APID
        });
        console.log("additemTagresponse", response);
      } catch (error) {
        console.error('Error adding tag:', error);
        // Handle the error appropriately
      }
    },
    async deleteitemTag({ dispatch }, payload) {
      try {
        //修改的时候新增字段nocache为true
        if (payload.noCache === undefined) {
          payload.noCache = true;
        }
        const response = await doPostRequest('/hkpma/api/references/tags', payload);
        //更新数据
        dispatch('fetchItems', {
          option: "get",
          APID: this.state.APID,
          noCache: true,
        });
        console.log("deleteTagresponse", response);
      } catch (error) {
        console.error('Error deleting tag:', error);
        // Handle the error appropriately
      }
    },
    //clinical data part
    async fetchStudyData({ commit, dispatch, state }, filters = {}) {
      console.log("fetchStudyData", filters)
      try {
        // Update filters in state if provided, otherwise use last filters
        if (Object.keys(filters).length) {
          commit('SET_FILTERS', filters);
        }
        const params = {
          ...state.currentFilters
        };
        const response = await doGetRequest('/hkpma/studies/get',
          params
        );
        console.log("fetchStudyData response", response)
        if (params.study_type === 'clinical') {
          commit('SET_CLINICAL_DATA', response.data.content);
          if (this.state.isLoading.api === "APIDetailpage") {
            this.state.APIdetail.clinicalStudyData = true;
            dispatch("checkAndSetLoadingFalse");
          }
        }
        if (params.study_type === 'non_clinical') {
          commit('SET_NON_CLINICAL_DATA', response.data.content);
          if (this.state.isLoading.api === "APIDetailpage") {
            this.state.APIdetail.non_clinicalStudyData = true;
            dispatch("checkAndSetLoadingFalse");
          }
        }

      } catch (error) {
        console.error('Error fetching clinical data:', error);
      }
    },


    async addStudyData({ dispatch }, newData) {
      try {
        if (newData.study_name === '' || newData.route === '' || newData.species === '' || newData.PoD === '' || newData.PDE === '' || newData.PDE_unit === '') {
          Modal.warning({
            title: 'Warning',
            content: 'Please fill in all the fields',
          });
          return;
        }
        //修改的时候新增字段nocache为true
        if (newData.noCache === undefined) {
          newData.noCache = true;
        }
        const response = await doPostRequest('/hkpma/studies/save', newData);
        console.log("addStudyDataresponse", response)
        //刷新数据
        await dispatch('fetchStudyData', {
          APID: newData.APID,
          study_type: newData.study_type,
          noCache: true
        });
        //console.log(...state.currentFilters)
        // console.log("1", response.data.content[0].id)
        // Modal.success({
        //   title: 'write to database',
        //   content: ["the id number:", response.data.content[0].id, " ", response.data.api_status,]
        // });
        Modal.success({
          title: 'write to database',
          content: [response.data.api_status,]
        });
      } catch (error) {
        console.error('Error adding Study data:', error);
        Modal.warning({
          title: 'Warning',
          content: error.message,
        });
      }
    },
    async deleteStudyData({ dispatch }, key) {
      try {
        console.log("deleteStudyData", key)
        await doPostRequest('/hkpma/studies/delete', {
          noCache: true,
          id: key.id,
          user: key.user
        });
        dispatch('fetchStudyData', {
          APID: key.APID,
          study_type: key.study_type,
          noCache: true
        });
      } catch (error) {
        console.error('Error deleting Study data:', error);
      }
    },
    //异步获取数据 根据APID

    async fetchItems({ dispatch, commit }, payload) {
      try {
        const response = await doPostRequest('/hkpma/api/references', payload);
        console.log("getItems response", response)
        commit('setItems', response.data.content);
        if (this.state.isLoading.api === "APIDetailpage") {
          this.state.APIdetail.items = true;
          dispatch("checkAndSetLoadingFalse");
        }
      }
      catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    async fetchtags({ dispatch, commit }, payload = { "option": "get" }) {
      try {

        const response = await doPostRequest('/hkpma/tags', payload);
        console.log("getTags response", response)
        commit('setTags', response.data.content);
        // 延迟500ms
        setTimeout(() => {
          if (this.state.isLoading.api === "APIDetailpage") {
            this.state.APIdetail.Tags = true;
            dispatch("checkAndSetLoadingFalse");
          }

        }
          , 0);
      }
      catch (error) {
        console.error('Error fetching tags:', error);
      }
    },
  },
})
